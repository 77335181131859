import styled from "styled-components";

export const Container = styled.section`
  margin: 10rem auto;
  max-width: 1200px;
  padding: 0 2rem;

  h2 {
    text-align: center;
    font-size: 3rem;
    margin-bottom: 5rem;
    color: #e2e8f0;
    
    span {
      color: #23ce6b;
      font-size: 1.8rem;
      vertical-align: middle;
      margin-left: 1rem;
    }
  }
`;

export const Timeline = styled.div`
  position: relative;
  padding-left: 5rem;

  &::before {
    content: '';
    position: absolute;
    left: 3.5rem;
    top: 0;
    bottom: 0;
    width: 2px;
    background: #2d3748;
  }
`;

export const CompanyLogo = styled.div`
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  flex-shrink: 0;
  transition: all 0.3s ease;
  margin-right: 3rem;
  overflow: hidden;
  border: 2px solid #2d3748;
  background: transparent;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const ExperienceCard = styled.div`
  display: flex;
  margin-bottom: 4rem;
  position: relative;
  transition: all 0.3s ease;

  &:hover {
    transform: translateX(10px);
    
    ${CompanyLogo} {
      transform: scale(1.1);
      border-color: #23ce6b;
    }
  }
`;

export const ExperienceContent = styled.div`
  background: #2d3748;
  border-radius: 1rem;
  padding: 2.5rem;
  flex-grow: 1;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  .experience-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }

  h3 {
    font-size: 1.8rem;
    color: #23ce6b;
    margin: 0;
  }

  h4 {
    font-size: 1.4rem;
    color: #a0aec0;
    margin-bottom: 1.5rem;
    font-weight: 500;
  }

  ul {
    padding-left: 2rem;
    margin-bottom: 2rem;

    li {
      margin-bottom: 0.8rem;
      line-height: 1.6;
      color: #e2e8f0;
    }
  }

  .tech-stack {
    display: flex;
    flex-wrap: wrap;
    gap: 0.8rem;

    span {
      background: #1a365d;
      color: #63b3ed;
      padding: 0.4rem 1rem;
      border-radius: 2rem;
      font-size: 0.9rem;
    }
  }
`;

export const DateBadge = styled.span`
  background: #1a365d;
  color: #63b3ed;
  padding: 0.4rem 1.2rem;
  border-radius: 2rem;
  font-size: 0.9rem;
  font-weight: 600;
`;

// Responsive styles
export const responsiveStyles = `
  @media (max-width: 768px) {
    ${Timeline} {
      padding-left: 3rem;
      
      &::before {
        left: 2.5rem;
      }
    }

    ${ExperienceCard} {
      flex-direction: column;
    }

    ${CompanyLogo} {
      margin-bottom: 1.5rem;
      margin-right: 0;
    }
  }

  @media (max-width: 480px) {
    ${Container} {
      padding: 0 1rem;
    }

    ${ExperienceContent} {
      padding: 1.5rem;
    }
  }
`;