import { Container, Timeline, ExperienceCard, CompanyLogo, ExperienceContent, DateBadge } from "./styles";
import cblogo from '../../assets/cblogo.svg';
import eupheus from '../../assets/eupheus.png';
import dreamsol from "../../assets/DreamSol@2x.png";

export function Experience() {
  return (
    <Container id="experience">
      <h2>Work Experience <span>(3+ Years)</span></h2>
      
      <Timeline>
        {/* Current Job */}
        <ExperienceCard>
          <CompanyLogo  style={{ backgroundColor: 'white' }}>
            <img src={cblogo} alt="CareerBuilder + Monster" />
          </CompanyLogo>
          <ExperienceContent>
            <div className="experience-header">
              <h3>Software Engineer I</h3>
              <DateBadge>Dec 2024 – Present</DateBadge>
            </div>
            <h4>CareerBuilder + Monster (AdTech Platform)</h4>
            <ul>
              <li>Designed workflows for Kevel AdTech events, integrating AWS with GCP</li>
              <li>Enhanced metadata for impressions/clicks to support CTR prediction</li>
              <li>Optimized real-time pipelines for pay-for-performance ad system</li>
              <li>Solved S3 wildcard and Pub/Sub filtering challenges</li>
            </ul>
            <div className="tech-stack">
              <span>Java</span>
              <span>Kotlin</span>
              <span>Spring Boot</span>
              <span>AWS/GCP</span>
            </div>
          </ExperienceContent>
        </ExperienceCard>

        {/* Second Job */}
        <ExperienceCard>
        <CompanyLogo  style={{ backgroundColor: 'white' }}>
            <img src={eupheus} alt="Eupheus Learning" />
          </CompanyLogo>
          <ExperienceContent>
            <div className="experience-header">
              <h3>Java Developer</h3>
              <DateBadge>May 2022 – Dec 2024</DateBadge>
            </div>
            <h4>Eupheus Learning, New Delhi</h4>
            <ul>
              <li>Developed student report cards system</li>
              <li>Streamlined client onboarding procedures</li>
              <li>Integrated SAP APIs for sales invoicing</li>
              <li>Migrated infrastructure from AWS to GCP</li>
            </ul>
            <div className="tech-stack">
              <span>Java</span>
              <span>Microservices</span>
              <span>ReactJS</span>
              <span>SAP/Salesforce</span>
            </div>
          </ExperienceContent>
        </ExperienceCard>

        {/* First Job */}
        <ExperienceCard>
        <CompanyLogo  style={{ backgroundColor: 'white' }}>
            <img src={dreamsol} alt="DreamSol" />
          </CompanyLogo>
          <ExperienceContent>
            <div className="experience-header">
              <h3>Java Developer</h3>
              <DateBadge>Jul 2021 – May 2022</DateBadge>
            </div>
            <h4>DreamSol Pvt Ltd, Noida</h4>
            <ul>
              <li>Built hospital management web apps (Spring Boot + React)</li>
              <li>Created interactive data dashboards</li>
              <li>Implemented Excel export with custom filters</li>
              <li>Developed automated monthly report emails</li>
            </ul>
            <div className="tech-stack">
              <span>Spring Boot</span>
              <span>React</span>
              <span>MySQL</span>
              <span>JavaScript</span>
            </div>
          </ExperienceContent>
        </ExperienceCard>
      </Timeline>
    </Container>
  );
}
